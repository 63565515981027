<template>
  <user-index class="flex items-center justify-center h-full">
    <div
      class="flex items-center justify-center w-full h-screen overflow-y-auto"
    >
      <div
        class="
          flex flex-col
          h-full
          place-content-between
          x-sm:w-full
          sm:w-2/3
          lg:w-1/3
          x-sm:px-4
          sm:px-0
          lg:px-0
        "
      >
        <!-- <div class="flex justify-between p-4 h-20  bg-qgo-primary-bg">
          <div class="">
            <img
              class="object-scale-down w-full h-full"
              src="../../assets/img/LoginLogo.png"
            />
          </div>
          <div class="">
            <p class="text-white">Get the bext experience</p>
            <p class="text-qgo-secondary-bg">Tap to download the app</p>
          </div>
          <div class="">
            c
          </div>
        </div> -->
        <div class="flex justify-center p-4 mt-5">
          <div class="flex justify-start w-full pt-5 mt-5">
            <img
              class="object-scale-down h-24"
              src="../../assets/img/LoginLogo.png"
            />
          </div>
        </div>
        <div class="p-1 mx-4">
          <div class="flex flex-col justify-center w-full">
            <div class="flex flex-col items-start justify-start mb-6">
              <p class="text-2xl font-bold text-qgo-primary-bg">
                CONFIRMING DETAILS
              </p>
              <p class="text-lg text-white">Checking your order</p>
            </div>
            <div class="items-start justify-start w-2/5 px-2 py-3 mb-4">
              <img
                class="object-scale-down w-full"
                src="../../assets/img/Go-Indeterminate.png"
              />
            </div>
          </div>
        </div>
        <div class="flex pt-1">
          <div class="flex h-56 pt-1 mx-4 mt-4 x-sm:w-2/5 sm:w-1/2 md:w-1/2">
            <Card class="w-40" :queue="scannedQrData"></Card>
          </div>
          <div
            class="
              flex flex-col
              items-start
              justify-start
              py-4
              text-white
              x-sm:px-2
              sm:px-4
              md:px-4
              x-sm:3/5
              sm:w-2/3
              md:w-2/3
            "
          ></div>
        </div>
      </div>
    </div>
  </user-index>
</template>

<script>
import UserIndex from "@/views/User/Index";
import Card from "@/views/User/Queue/QueueCards.vue";

export default {
  data() {
    return {
      response: {},
      scannedQrData: {
        order_no: null,
        vendor_id: null,
        vendor_detail: { logo: null },
      },
    };
  },
  components: {
    UserIndex,
    Card,
  },
  methods: {
    finishLoading() {
      this.$router.push({ name: "UserQueueTicket" });
    },
    processOrderDetail() {
      this.$store.commit("queues/setLoading", true);
      let body = this.$store.getters["queues/orderDetail"];
      console.log(body);
      //axios call send inputted data to API
      this.$http
        .post("user/newuser ", body)
        .then((response) => {
          let queue = response.data.data;
          let ticket_name =
            queue.ticket_num < 10
              ? "Q0" + queue.ticket_num
              : "Q" + queue.ticket_num;
          let payload = {
            customer_name: queue.customer_name,
            customer_id: queue.customer_id,
            vendor_id: queue.vendor_id,
            id: queue.id,
            status: queue.status,
            ticket_name: ticket_name,
          };
          let userId = { userId: queue.customer_id };
          localStorage.setItem("userId", JSON.stringify(userId));
          this.$store.commit("queues/setIndividualQueueData", payload);
          this.$store.commit("queues/setLoading", false);
          this.$router.push({ name: "UserQueueTicket" });
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Something went wrong while joining you!",
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          this.$store.commit("queues/setLoading", false);
          console.error("There was an error!", error);
        });
    },
    onPageLoad() {
      let orderDetails = localStorage.getItem("scannedQRDetails");
      if (orderDetails) {
        orderDetails = JSON.parse(orderDetails);
        this.scannedQrData = {
          vendor_id: orderDetails.vendor_id,
          order_no: orderDetails.order_id,
          vendor_detail: {
            logo: orderDetails.vendor_logo,
          },
        };
        this.assignQRToUser();
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Something went wrong. Error while getting order details",
          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }
    },
    assignQRToUser() {
      this.$http
        .post("qr/assign", {
          vendor_id: this.scannedQrData.vendor_id,
          order_no: this.scannedQrData.order_no,
        })
        .then((response) => {
          if (response && response.data.status) {
            this.$router.push({ name: "UserQueueTicket" });
          }
        })
        .catch((error) => {
          if (
            error.response.data.message ===
            "QR codes has been already assigned."
          ) {
            this.$router.push({ name: "UserQueueTicket" });
          } else {
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
            this.$router.push({ name: "UserSignIn" });
          }
        });
    },
  },
  computed: {
    queue() {
      return this.$store.getters["queues/singleQueueData"];
    },
  },
  mounted() {
    // window.setTimeout(this.finishLoading, 3000);
    // this.processOrderDetail();
    this.onPageLoad();
  },
};
</script>
